export default [
    {
        name: "Kinder Beat<span class='reg'>&reg;</span>",
        link: "courses",
        desc: "Only available to Licensed Kinder Beat Teachers.",
        three: true,
        products: [
            {
                name: "Kinder Beat 1: Music Box Magic",
                desc: "Book / Audio / Kinder Bear",
                filename: "kinder_beat_1_music_box_magic_cover.jpg",
                sku: "KBCK001",
                price: 40
            },
            {
                name: "Kinder Beat 2: Percussion Play",
                desc: "Book / Audio / Kinder Roo & Joey",
                filename: "kinder_beat_2_percussion_play_cover.jpg",
                sku: "KBCK002",
                price: 40
            },
            {
                name: "Kinder Beat 3: Piano Prep",
                desc: "Book / Audio / Super Cool Mouse",
                filename: "kinder_beat_3_piano_prep_cover.jpg",
                sku: "KBCK003",
                price: 40
            },
            {
                name: "Teacher Guide - Kinder Beat 1",
                desc: "Book / Audio / Flash Cards",
                filename: "KB1_TG.jpg",
                sku: "KBTG001",
                price: 110
            },
            {
                name: "Teacher Guide - Kinder Beat 2",
                desc: "Book / Audio / Flash Cards / Worksheets",
                filename: "KB2_TG.jpg",
                sku: "KBTG002",
                price: 110
            },
            {
                name: "Teacher Guide - Kinder Beat 3",
                desc: "Book / Audio / Flash Cards / Worksheets",
                filename: "KB3_TG.jpg",
                sku: "KBTG003",
                price: 110
            }
        ],
        kb: true
    },
    {
        name: "Encore on Keys - Junior Piano",
        link: "keys",
        products: [
            {
                name: "Junior Piano 1",
                filename: "junior_piano_1_cover.jpg",
                desc: "Book / Audio / Flash Cards",
                sku: "JSCK001",
                price: 39.95
            },
            {
                name: "Junior Piano 2",
                filename: "junior_piano_2_cover.jpg",
                desc: "Book / Audio / Flash Cards",
                sku: "JSCK002",
                price: 39.95
            },
            {
                name: "Junior Piano 3",
                filename: "junior_piano_3_cover.jpg",
                desc: "Book / Audio / Flash Cards",
                sku: "JSCK003",
                price: 39.95
            },
            {
                name: "Junior Piano 4",
                filename: "junior_piano_4_cover.jpg",
                desc: "Book / Audio / Flash Cards",
                sku: "JSCK004",
                price: 39.95
            },
            {
                name: "Teacher Guide - Junior Piano 1",
                filename: "J1_TG_Cover_2016-600px.png",
                sku: "JSTG001",
                price: 45.00
            },
            {
                name: "Teacher Guide - Junior Piano 2",
                filename: "J2_TG_Cover_2016-600px.png",
                sku: "JSTG002",
                price: 45.00
            }
        ]
    },
    {
        name: "Encore on Keys - Primary Piano",
        products: [
            {
                name: "Primary Piano 1",
                filename: "primary_piano_1_cover.jpg",
                desc: "Book / Audio / Flash Cards",
                sku: "PSCK001",
                price: 39.95
            },
            {
                name: "Primary Piano 2",
                filename: "primary_piano_2_cover.jpg",
                desc: "Book / Audio / Flash Cards",
                sku: "PSCK002",
                price: 39.95
            },
            {
                name: "Teacher Guide - Primary Piano 1",
                filename: "P1_TG_Cover_2016-600px.png",
                sku: "PSTG001",
                price: 45.00
            },
            {
                name: "Teacher Guide - Primary Piano 2",
                filename: "P2_TG_Cover_2016-600px.png",
                sku: "PSTG002",
                price: 45.00
            }
        ]
    },
    {
        name: "Encore on Keys - Accent Piano",
        products: [
            {
                name: "Accent Piano 1",
                filename: "accent_piano_1_cover.jpg",
                desc: "Book / Audio / Worksheets",
                sku: "AKCK001",
                price: 32.95
            },
            {
                name: "Accent Piano 2",
                filename: "accent_piano_2_cover.jpg",
                desc: "Book / Audio / Worksheets",
                sku: "AKCK002",
                price: 32.95
            },
        ]
    },
    {
        name: "Encore on Keys - Achiever Piano",
        products: [
            {
                name: "Achiever Piano 1",
                filename: "achiever_1_cover.jpg",
                desc: "Preliminary | Book / Audio",
                sku: "ASCK001",
                price: 36.95
            },
            {
                name: "Achiever Piano 2",
                filename: "achiever_2_cover.jpg",
                desc: "Grade One | Book / Audio",
                sku: "ASCK002",
                price: 36.95
            },
            {
                name: "Achiever Piano 3",
                filename: "achiever_3_cover.jpg",
                desc: "Grade Two | Book / Audio",
                sku: "ASCK003",
                price: 36.95
            },
            {
                name: "Achiever Piano 4",
                filename: "achiever_4_cover.jpg",
                desc: "Grades Three & Four | Book / Audio",
                sku: "ASCK004",
                price: 36.95
            },
        ]
    },
    {
        name: "Encore on Strings - Music Maestros",
        link: "strings",
        products: [
            {
                name: "Violin Level 1",
                desc: "Book / Audio",
                filename: "violin_1_cover.jpg",
                sku: "MMCK01V",
                price: 34.95
            },
            {
                name: "Viola Level 1",
                desc: "Book / Audio",
                filename: "viola_1_cover.jpg",
                sku: "MMCK01A",
                price: 34.95
            },
            {
                name: "Cello Level 1",
                desc: "Book / Audio",
                filename: "cello_1_cover.jpg",
                sku: "MMCK01C",
                price: 34.95
            },
            {
                name: "Bass Level 1",
                desc: "Book / Audio",
                filename: "bass_1_cover.jpg",
                sku: "MMCK01B",
                price: 34.95
            },
            {
                name: "Violin Level 2",
                desc: "Book / Audio",
                filename: "violin_2_cover.jpg",
                sku: "MMCK02V",
                price: 34.95
            },
            {
                name: "Viola Level 2",
                desc: "Book / Audio",
                filename: "viola_2_cover.jpg",
                sku: "MMCK02A",
                price: 34.95
            },
            {
                name: "Cello Level 2",
                desc: "Book / Audio",
                filename: "cello_2_cover.jpg",
                sku: "MMCK02C",
                price: 34.95
            },
            {
                name: "Bass Level 2",
                desc: "Book / Audio",
                filename: "bass_2_cover.jpg",
                sku: "MMCK02B",
                price: 34.95
            },
            {
                name: "Accompaniment & Ensemble Book 1",
                filename: "ensemble-book1.jpg",
                sku: "MMEB001",
                price: 75
            },
            {
                name: "Accompaniment & Ensemble Book 2",
                filename: "ensemble-book2.jpg",
                sku: "MMEB002",
                price: 120
            }
        ]
    },
    {
        name: "Theory Wiz Fundamentals",
        link: "tw",
        products: [
            {
                name: "Theory Wiz 1 - Beginner",
                filename: "Theory-wiz-1.jpg",
                sku: "THWF001",
                price: 19.95
            },
            {
                name: "Theory Wiz 2 - Preliminary",
                filename: "Theory-wiz-2.jpg",
                sku: "THWF002",
                price: 19.95
            },
            {
                name: "Theory Wiz 3 - Grade One",
                filename: "Theory-wiz-3.jpg",
                sku: "THWF003",
                price: 19.95
            }
        ]
    },
    {
        name: "Music Journal - Student Practice Record",
        link: "journal",
        products: [
            {
                name: "Music Journal",
                desc: "A comprehensive student practice record for all ages, with essential music history and theory references",
                filename: "music-journal-cover.png",
                sku: "APJ002",
                link: {
                    name: "courses",
                    params: {
                        prefix: "journal"
                    }
                },
                price: 5.95
            },
        ]
    },
    {
        name: "Encore Music Mats",
        link: "materials",
        products: [
            {
                name: "Keyboard Mat",
                desc: "635mm x 170mm",
                filename: "Music-mat-keyboard.png",
                sku: "SMKL001",
                price: 45
            },
            {
                name: "Treble Staff Mat",
                desc: "560mm x 180mm",
                filename: "Music-mat-treble.png",
                sku: "SMTS001",
                price: 45
            },
            {
                name: "Bass Staff Mat",
                desc: "560 x 180mm",
                filename: "Music-mat-bass.png",
                sku: "SMBS001",
                price: 45
            },
        ]
    },
    {
        name: "Teaching Aids / Materials",
        products: [
            {
                name: "Character Stickers - Junior",
                desc: "2 sheets / 192 stickers",
                filename: "junior-stickers.jpg",
                sku: "JCS001",
                price: 7.50
            },
            {
                name: "Character Stickers - Primary",
                desc: "2 sheets / 192 stickers",
                filename: "primary-stickers.jpg",
                sku: "PCS001",
                price: 7.50
            },
            {
                name: "Music History Map - A3 (Gloss)",
                desc: "Full colour poster. Detailed Summary of each musical period including the main features, dates and important composers.",
                filename: "Music_History_Map_web.png",
                sku: "MHM001",
                spanMultiple: true,
                price: 20,
            },
            {
                name: "Music History Map - A3 (Gloss + Laminated)",
                desc: "Full colour poster. Detailed Summary of each musical period including the main features, dates and important composers.",
                filename: "Music_History_Map_web.png",
                sku: "MHM002",
                spanMultiple: true,
                price: 25,
                linkPrev: true
            },
            {
                name: "Music History Map - A2 (Gloss)",
                desc: "Full colour poster. Detailed Summary of each musical period including the main features, dates and important composers.",
                filename: "Music_History_Map_web.png",
                sku: "MHM003",
                spanMultiple: true,
                price: 25,
                linkPrev: true
            },
            {
                name: "Music History Map - A2 (Gloss + Laminated)",
                desc: "Full colour poster. Detailed Summary of each musical period including the main features, dates and important composers.",
                filename: "Music_History_Map_web.png",
                sku: "MHM004",
                spanMultiple: true,
                price: 35,
                linkPrev: true
            },
        ]
    }
];