<template>
	<div class="home">
		<div id="content" class="home-content blue-box curved" style="--accent: #00a7e5">
			<svg width="500" height="200" viewBox="0 0 500 200" preserveAspectRatio="none">
				<path d="M0,200 L0,26 Q250,-16 500,26 L500,200 Q250,160 0,200Z" fill="#cee7f0"/>
				<path d="M0,30 L0,25 Q250,-15 500,25 L500,30 Q250,-10 0,30Z" fill="#00a7e5"/>
			</svg>

            <div class="content-wide centered-banner">
				<p class="banner-title">Order Our Products</p>
            </div>
		</div>

		<div class="order-summary-button-container" v-if="showSummary">
			<p class="order-summary-button" @click="scrollToOrderSummary">View Order Summary</p>
		</div>

		<div id="content">
			<div class="order-header">
				<p class="heading">Important Information</p>
				<p class="info">This order page is ONLY for customers from <span class="info-bold">Australia and New Zealand</span>. For all other countries, <router-link :to="{ name: 'contact', params: { scrollInfo: true }}">please contact your local representative</router-link>.</p>
				<p class="info">Please note that payment cannot occur on this website. Our office will contact you to fulfill your order once you have submitted this form.</p>
				<p class="info">All prices are in <span class="info-bold">Australian Dollars (AUD)</span> and exclude shipping costs. Price variation between countries is due to shipping costs, exchange rates, local taxes and duties.</p>
				<p class="heading-3 red">Kinder Beat<span class="reg">&reg;</span> materials may only be purchased by current Kinder Beat Licensees.</p>
			</div>

			<div class="order-button-row">
				<div class="order-button" @click="scrollToSection('courses')">Kinder Beat</div>
				<div class="order-button" @click="scrollToSection('keys')">Encore on Keys</div>
				<div class="order-button" @click="scrollToSection('strings')">Encore on Strings</div>
				<div class="order-button" @click="scrollToSection('tw')">Theory Wiz</div>
				<div class="order-button" @click="scrollToSection('journal')">Music Journal</div>
				<div class="order-button" @click="scrollToSection('materials')">Teaching Aids</div>
				<div class="order-button" @click="scrollToSection('cert')">Certificates</div>
			</div>

			<div class="product-category" v-for="(productCategory, i) in products" :key="productCategory.name">
				<p class="heading-2" v-html="productCategory.name" :class="productCategory.link ? `section-${productCategory.link}` : ''"></p>
				<p class="info-bold" v-if="productCategory.desc">{{ productCategory.desc }}</p>

				<div class="products-grid" :class="{'three' : productCategory.three}">
					<div class="product" v-for="(product, j) in filterSub(productCategory.products)" :key="product.sku" :class="{ multiple: product.spanMultiple, 'product-sub': product.sub }">
						<img :src="imgUrls[i][j]" @click="imageClick(product.link)" :class="{ 'link': !!product.link }" v-if="imgUrls[i]">
						<p class="product-name" @click="imageClick(product.link)" :class="{ 'link': !!product.link }">{{ product.name }}</p>
						<p class="product-desc" v-html="product.desc"></p>

						<p class="product-price">{{ product.price | price }}</p>
						
						<div class="select-row">
							<p class="product-sku" v-if="product.sku">{{ product.sku }}</p>
							<input type="number" min="0" placeholder="Quantity" v-model="productCategory.products.find(val => val.sku == product.sku).qty">
						</div>

						<div v-for="subProduct in product.sub" :key="subProduct.sku" class="sub-box">
							<p class="product-name" @click="imageClick(subProduct.link)" :class="{ 'link': !!subProduct.link }">{{ subProduct.name }}</p>
							<p class="product-desc" v-html="subProduct.desc"></p>

							<p class="product-price">{{ subProduct.price | price }}</p>
							
							<div class="select-row">
								<p class="product-sku" v-if="subProduct.sku">{{ subProduct.sku }}</p>
								<input type="number" min="0" placeholder="Quantity" v-model="productCategory.products.find(val => val.sku == subProduct.sku).qty">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="product-category section-cert">
				<p class="heading">Certificates of Achievement</p>
				<p class="product-price">$2.95</p>
				<p class="info">Fully embossed student certificates printed on high-quality 190gsm stock. Available for all Kinder Beat<span class="reg">&reg;</span> and Encore on Keys titles. Please indicate quantities below:</p>

				<div class="certificate">
					<div class="left">
						<img src="@/assets/images/achiever-cert-1.png">
					</div>

					<div class="right">
						<div class="select-row certificates">
							<p class="select-row-title">Kinder Beat 1</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTKB1">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Kinder Beat 2</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTKB2">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Kinder Beat 3</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTKB3">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Junior Piano 1</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTJS1">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Junior Piano 2</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTJS2">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Junior Piano 3</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTJS3">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Junior Piano 4</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTJS4">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Primary Piano 1</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTPS1">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Primary Piano 2</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTPS2">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Accent Piano 1</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTAK1">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Accent Piano 2</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTAK2">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Achiever Piano 1</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTAS1">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Achiever Piano 2</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTAS2">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Achiever Piano 3</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTAS3">
						</div>
						<div class="select-row certificates">
							<p class="select-row-title">Achiever Piano 4</p>
							<input type="number" min="0" placeholder="Quantity" v-model="certificates.CERTAS4">
						</div>
					</div>
				</div>
			</div>
			
			<div class="order-summary">
				<p class="heading">Summary</p>
				<p class="heading-3 red" v-if="orderKinderBeat">Your order includes Kinder Beat<span class="reg">&reg;</span> materials. Please ensure that your licence is current.</p>
				
				<div v-if="showSummary">
					<p class="info">This order page is ONLY for customers from <span class="info-bold">Australia and New Zealand</span>. For all other countries, <router-link :to="{ name: 'contact', params: { scrollInfo: true }}">please contact your local representative</router-link>.</p>

					<table class="order-summary-table" >
						<tr>
							<th>Name</th>
							<th>SKU</th>
							<th>Quantity</th>
							<th>Total</th>
						</tr>
						
						<tbody v-for="productCategory in products" :key="productCategory.name">
							<tr v-for="product in productCategory.products.filter(item => item.qty > 0)" :key="product.sku">
								<td class="name">{{ product.name }}</td>
								<td class="sku">{{ product.sku }}</td>
								<td class="qty"><input type="number" min="0" placeholder="Quantity" v-model="product.qty"></td>
								<td class="total">{{ product.price * product.qty | price }}</td>
							</tr>
						</tbody>

						<tr v-for="item in Object.keys(certificates).filter(e => certificates[e] > 0)" :key="item">
							<td class="name">Certificate of Achievement</td>
							<td class="sku">{{ item }}</td>
							<td class="qty"><input type="number" min="0" placeholder="Quantity" v-model="certificates[item]"></td>
							<td class="total">{{ 2.95 * certificates[item] | price }}</td>
						</tr>
					</table>

					<p class="product-price">Total: {{ totalPrice | price }}</p>

					<div class="button-container">
						<button v-if="!showForm" class="button-round" @click="continueOrder">Submit Order</button>
					</div>
				</div>

				<div v-else>
					<p class="summary-info">
						You have nothing selected. Please enter the quantities you would like on each item to continue.
					</p>
				</div>
			</div>

			<div class="form-container" v-show="showForm">
				<p class="heading-2">Your Details</p>
				<p class="info warning">Please note that payment cannot occur on this website. Our office will contact you to fulfill your order once you have submitted this form.</p>
				<br>
				<br>
				<form id="contact-form" v-on:submit.prevent="" v-show="!sent">
					<label for="name" :class="{ 'error': nameRequired && showError }">Name (required)</label>
					<input type="text" id="name" v-model="contactFormData.name">

					<label for="email" :class="{ 'error': emailRequired && showError }">Email (valid email required)</label>
					<input type="text" id="email" v-model="contactFormData.email">

					<label for="phone">Phone</label>
					<input type="text" id="phone" v-model="contactFormData.phone">

					<label for="delivery" :class="{ 'error': addressRequired && showError }">Delivery Address (required)</label>
					<input type="text" id="delivery" v-model="contactFormData.deliveryAddress" ref="searchBox">

					<div>
						<label for="type" class="for-select">I am a</label>
						<select name="type" id="type" v-model="contactFormData.type">
							<option value="teacher">Teacher</option>
							<option value="parent">Parent/Other</option>
						</select>
					</div>

					<div>	
						<label for="materials" class="for-select">Do you currently use any Encore materials?</label>
						<select name="materials" id="materials" v-model="contactFormData.materials">
							<option :value="true">Yes</option>
							<option :value="false">No</option>
						</select>
					</div>
 
					<label for="findOutMessage">If no, then how did you find out about us?</label>
					<input type="text" id="findOutMessage" v-model="contactFormData.findOutMessage">

					<div v-if="contactFormData.type=='teacher'">
						<label for="subscribe" class="for-select">Would you like to subscribe to our online newsletter?</label>
						<select name="subscribe" id="subscribe" v-model="contactFormData.subscribeToVision6">
							<option :value="true">Yes</option>
							<option :value="false">No</option>
						</select>
					</div>

					<label for="message">Message</label>
					<textarea id="message" name="message" rows="4" v-model="contactFormData.message">

					</textarea>

					<p class="info" v-if="showError">Please make sure all required fields are filled out.</p>

					<div class="submit-container">
						<button class="button-round" type="submit" @click="contactSubmit" :disabled="submitPrevent">Send</button>
					</div>
				</form>

				<div v-show="sent">
					<p class="info">Thank you for your order. Our office will contact you soon.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { functions } from '@/firebase'
import products from '@/products';
import { storage } from '@/firebase';

export default {
	props: [
		'section'
	],
	data() {
		return {
			products: products,
			imgUrls: [],
			showForm: false,
			sent: false,
			contactFormData: {
				name: '',
				email: '',
				phone: '',
				deliveryAddress: '',
				type: 'teacher',
				materials: false,
				findOutMessage: '',
				subscribeToVision6: false,
				message: ''
			},
			nameRequired: true,
			emailRequired: true,
			addressRequired: true,
			showError: false,
			certificates: {
				CERTKB1: 0,
				CERTKB2: 0,
				CERTKB3: 0,
				CERTJS1: 0,
				CERTJS2: 0,
				CERTJS3: 0,
				CERTJS4: 0,
				CERTPS1: 0,
				CERTPS2: 0,
				CERTAK1: 0,
				CERTAK2: 0,
				CERTAS1: 0,
				CERTAS2: 0,
				CERTAS3: 0,
				CERTAS4: 0
			},
			submitPrevent: false
		}
	},
	computed: {
		productsReduced() {
			return this.products.reduce((accumulator, val) => {
				return accumulator.concat(val.products);
			}, []);
		},
		orderKinderBeat() {
			return this.products.filter(t => t.kb).reduce((accumulator, val) => {
				return accumulator += val.products.reduce((a, v) => {
					return a += v.qty ? v.qty : 0;
				}, 0);
			}, 0) > 0
					|| this.certificates.CERTKB1 > 0
					|| this.certificates.CERTKB2 > 0
					|| this.certificates.CERTKB3 > 0;
		},
		showSummary() {
			return this.productsReduced.some(item => item.qty > 0) || Object.values(this.certificates).reduce((accumulator, val) => {
				return accumulator + val;
			}, 0) > 0;
		},
		totalPrice() {
			return this.productsReduced.reduce((accumulator, val) => {
				return ((val.qty ? val.qty : 0) * val.price) + accumulator
			}, 0) + Object.values(this.certificates).reduce((accumulator, val) => {
				return (val * 2.95) + accumulator
			}, 0)
		}
	},
	mounted() {
		this.products.forEach((category, i) => {
			category.products.filter(val => !val.linkPrev).forEach((product, j) => {
				this.$set(this.imgUrls, i, []);
				storage.ref(`products/${product.filename}`).getDownloadURL().then(url => {
					this.$set(this.imgUrls[i], j, url);
				});
			});
		});

		setTimeout(() => {
			this.scrollToSection(this.section);
		}, 1000);
	},
	metaInfo() {
		return {
			script: [{
				src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAXypMM2uB-mioDJ-xX5sCSEAL2fiZGgMY&libraries=places',
				async: true,
				defer: true,
				callback: () => this.initAutocomplete()
			}]
		}
	},
	methods: {
		filterSub(productsList) {
			return productsList.reduce((prev, val) => {
				if (val.linkPrev && prev.length > 0) {
					var previousProduct = prev[prev.length - 1]

					if (!previousProduct.sub) {
						previousProduct.sub = []
					}

					previousProduct.sub.push(val)
				} else {
					prev.push({ ...val })
				}

				return prev
			}, [])
		},
		scrollToOrderSummary() {
			this.$scrollTo('.order-summary', { offset: -140 });
		},
		continueOrder() {
			this.showForm = true;
		},
		imageClick(link) {
			if (!link) {
				return;
			}

			this.$router.push({ name: link.name, params: link.params });
		},
		sidebar(name) {
			this.$scrollTo(name, { offset: -140 });
		},
		contactSubmit() {
			if (this.submitPrevent) {
				return;
			}

			this.submitPrevent = true;			

			if (!this.validateForm()) {
				this.submitPrevent = false;
				return;
			}

			if (this.contactFormData.type == 'parent') {
				this.contactFormData.subscribeToVision6 = false;
			}
			
			var productsFiltered = this.productsReduced.filter(item => item.qty > 0);

			functions.httpsCallable('orderForm')({
				form: this.contactFormData,
				products: productsFiltered,
				certs: this.certificates,
				total: this.totalPrice
			}).then(() => {
				this.sent = true;
			});
		},
		validateForm() {
			const valid = !(this.nameRequired || this.emailRequired || this.addressRequired);
			this.showError = !valid;
			return valid;
		},
		validEmail(email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		initAutocomplete() {
			const defaultBounds = {
				north: -10,
				south: -45,
				east: 156,
				west: 110,
			};
			this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs['searchBox'], {
				bounds: defaultBounds,
				strictBounds: false
			});
			this.autocomplete.setFields(["formatted_address", "geometry"]);

			this.autocomplete.addListener("place_changed", () => {
                const place = this.autocomplete.getPlace();

                this.contactFormData.deliveryAddress = place.formatted_address;
			});
        },
		scrollToSection(section) {
			this.$scrollTo(`.section-${section}`, { offset: -120 });
		}
	},
	watch: {
		contactFormData: {
			deep: true,
			handler(val) {
				this.nameRequired = val.name === "";
				this.emailRequired = !this.validEmail(val.email);
				this.addressRequired = val.deliveryAddress === "";
			}
		}
	},
	filters: {
		price(val) {
			if (val) {
				return `$${val.toFixed(2)}`
			} else {
				return ""
			}
		}
	}
}
</script>